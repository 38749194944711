try{
    //feature detect arrow functions for supported browsers
    var t = new Function('x', 'return ((x) => x + x)(x)');
	t(2);
}catch(e){
    //arrow functions not supported
    var count = +localStorage.getItem('browser-warning-view-count') || 0;
    var host = localStorage.getItem('browser-warning-hostname');
    var time = +localStorage.getItem('browser-warning-time') || 0;

    if(count % 10 === 0 || host !== location.hostname || time < (Date.now() - (1000 * 60 * 60))){
        var script = document.createElement('script');
        script.src = 'https://shared.automatit.net/browser_warning/v1/browser_support_warning.js';
        var addTo = document.body ? document.body : document.childNodes[0];
        addTo.appendChild(script);
        localStorage.setItem('browser-warning-time', Date.now());

    }
    localStorage.setItem('browser-warning-view-count', ++count);
    localStorage.setItem('browser-warning-hostname', location.hostname);
}